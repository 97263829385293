import React, { Component } from 'react'
import Link from '../utils/link'

import NewsletterForm from './newsletter'

import Logo from '../assets/images/logo.svg'
import LinkedIn from '../assets/images/linkedin-white.svg'
import Email from '../assets/images/email.svg'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <h4><Link to='tel:+61390920475'>+61 3 9092 0475</Link><br />
            <Link to='mailto:info@bio101.com'>info@bio101.com</Link></h4>
            <p>Suite 201/697 Burke Road<br />
            Camberwell VIC 3124</p>
          </div>
          <div className='footer__col'>
            <ul>
              <li><Link to='/about-us'>About Us</Link></li>
              <li><Link to='/case-studies'>Case Studies</Link></li>
              <li><Link to='/people'>Team</Link></li>
              <li><Link to='/contact'>Contact Us</Link></li>
            </ul>
          </div>
          <div className='footer__col'>
            <ul className='footer__social'>
              <li><Link to='https://www.linkedin.com/company/bio101/'><img src={LinkedIn} alt='Bio101 - LinkedIn' /></Link></li>
              <li><Link to='mailto:info@bio101.com'><img src={Email} alt='Bio101 - Email' /></Link></li>
            </ul>
            <NewsletterForm />
          </div>
          <div className='footer__col'>
            <Link to='/' title='Bio101' className='footer__logo'>
              <img src={Logo} alt='Bio101' />
            </Link>
            <p className='footer__credits'>Copyright { new Date().getFullYear() } Bio101 Financial Advisory Pty Ltd
            <br />All rights reserved. <Link to='/privacy-policy'>Privacy Policy</Link>
            <br />Design by <Link to='https://atollon.com.au/?utm_source=bio101&utm_medium=website&utm_campaign=referrer&utm_term=service' target='_blank' rel='noopener noreferrer'>Atollon</Link></p>
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer
